import React from 'react'

const Curriculum_note = () => {
  return (
    <>
    <div className="alert alert d-flex align-items-center mt-5" role="alert" style={{"backgroundColor": "#002347", "color": "#fff"}}>
    <i class="fa-solid fa-arrow-right me-2"></i>
    <div>
    To gain further insights into the course, kindly download the course curriculum.
    </div>
  </div>
    </>
  )
}

export default Curriculum_note